import React, { useContext, useState, useRef, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Alert from '../../common/Alert'
import { APIContext } from '../../../utils/api'
import { colors } from '../../../themes/colors'
import creditCardLogos from '../../../assets/images/credit-card-logos.svg'
import secureLogos from '../../../assets/images/secure-logos.svg'
import styles from './styles.module.css'

// component function
export default function AccountPayment(props) {

	const {org} = props

	const context = useContext(APIContext)
	const [alertTask, setAlertTask] = useState()
	const [card, setCard] = useState()
	const [canSubmit, setCanSubmit] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const alertObjectRef = useRef(null)
	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	const plan = org.subscriptionPlan
	const calculations = org.subscriptionCalculations
	const country = org.companyStoreData.customer.address?.country
	const taxFactor = country === 'DK' ? .25 : 0
	const taxTxt = taxFactor > 0 ? '(incl. ' + (taxFactor * 100) + '% tax) ' : ' '
	const price = plan ? plan.config.Monthly_billing ? numberWithCommas(plan.config.Monthly_ex_VAT_Monthly_billing * (1+taxFactor)) : numberWithCommas(plan.config.Monthly_ex_VAT_Annual_billing * (1+taxFactor)) : ''
	const cycleName = plan?.config.Calc_cycle_length_month === 12 ? 'yearly' : 'monthly'
	const subRenew = calculations && new Date(calculations.currentPeriodEnd).toLocaleDateString("en-GB", dateOptions)

	const stripeCardElementsOptions = {
		hidePostalCode: true,
		style: {
			base: {
				iconColor: colors.cardIcon,
				color: colors.text,
				fontFamily: 'Greycliff, Helvetica, Arial, Lucida, sans-serif',
				fontSize: '18px',
				fontSmoothing: 'antialiased',
				'::placeholder': {
					color: colors.textLabel
				}
			}
		}
	}
	const stripe = useStripe()
	const elements = useElements()

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// current credit card brand and last 4 digits
	useEffect(() => {
		getCurrentCard()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket])

	// get info about current card
	function getCurrentCard(updated) {
		context.io.socket.get('/api/v1/subscription/customer/payment-method', (data, res) => {
			if (res.statusCode === 200) {
				setCard(data.card)
				if (updated) {
					elements.getElement(CardElement).clear()
					showAlert('Credit card has been updated')
				}
			} else if (res.statusCode === 404) {
				// no credit card
			} else {
				showAlert('Error getting credit card info: ' + res.error.message)
			}
			setSubmitting(false)
		})
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// add thousand separators to number
	function numberWithCommas(val) {
	    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	// update payment method
	function updatePaymentMethod() {
		if (submitting) return
		setSubmitting(true)
		context.io.socket.get('/api/v1/subscription/customer/setup-intent', (data, res) => {
			if (res.statusCode === 200) {
				stripe.confirmCardSetup(data.client_secret,{payment_method:{card:elements.getElement(CardElement)}}).then(result => {
					if (result.error) {
						showAlert('Error: ' + (result.error.message || result.error.error.decline_code))
						setSubmitting(false)
					} else {
						const req = { paymentMethodId: result.setupIntent.payment_method }
						context.io.socket.patch('/api/v1/subscription/customer/payment-method', req, (data, res) => {
							if (res.statusCode === 200) {
								getCurrentCard(true) // update card info on page
							} else {
								showAlert('Error updating credit card: ' + res.error.message)
								setSubmitting(false)
							}
						})
					}
				})
			} else {
				showAlert('Error updating credit card: ' + res.error.message)
				setSubmitting(false)
			}
		})
	}

	const cardTxt = (card && calculations?.subCancel === 0 && !calculations?.subExpired ?
		'You are billed ' + price + ' € ' + taxTxt + cycleName + ' in advance on this credit card. Next due date is ' + subRenew + '.' :
		'You are not billed on this card because your subscription has expired or has been canceled.'
	)

	return (
		<div className={styles.accountElementBack}>
			<div className={styles.paymentContainer}>
				<h2>Credit card</h2>
				<div className={styles.paymentBox}>
					<div className={styles.paymentBoxLeft}>
						<div className={styles.label}>Current credit card</div>
						<div style={{fontFamily:'Greycliff demibold'}}>{card ? '[' + card.brand.charAt(0).toUpperCase()+card.brand.slice(1) + '] xxxx-xxxx-xxxx-' + card.last4 : 'No credit card added'}</div>
						<p style={{margin:'18px 0 -7px'}}>{cardTxt}</p>
					</div>
					<div className={styles.paymentBoxRight}>
						<div className={styles.cardElementContainer}>
							<CardElement options={stripeCardElementsOptions} onChange={e=>setCanSubmit(e.complete)}/>
						</div>
						<div className={styles.logoContainer}>
							<img className={styles.cardLogos} src={creditCardLogos} alt="Credit card logos" />
							<img className={styles.secureLogos} src={secureLogos} alt="Stripe and 3D secure" />
						</div>
					</div>
				</div>
				<button tabIndex="-1" disabled={!canSubmit || submitting} className="fs-button accountSubmitBtn" onClick={updatePaymentMethod}>{submitting ? card ? 'UPDATING CARD...' : 'ADDING CARD...' : card ? 'UPDATE CREDIT CARD' : 'ADD CREDIT CARD'}</button>
			</div>
			{alert}
		</div>
	)
}
