import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import AnalysisProgressBar from '../common/AnalysisProgressBar'
import Alert from '../common/Alert'
import { APIContext } from '../../utils/api'
import { getMediaTypeById } from '../../utils/mediaTypes'
import { getIndustryBySubIndustryId } from '../../utils/industries'
import iconAnalysis from '../../assets/images/icon-analysis.svg'
import styles from './styles.module.css'

// component function
export default function AnalysisProgress(props) {

	// progress default object
	const progressInit = {
		todo: { transcode: 1, preProc: 1, objectDetection: 1, objectDetectionPostProc: 1, mapImage: 1, flowsam: 1, mapImageBlend: 1, attentionCalc: 1, statisticsCalc: 1 },
		done: { transcode: 0, preProc: 0, objectDetection: 0, objectDetectionPostProc: 0, mapImage: 0, flowsam: 0, mapImageBlend: 0, attentionCalc: 0, statisticsCalc: 0 }
	}

	const context = useContext(APIContext)
	const [uploading, setUploading] = useState(true)
	const [uploaded, setUploaded] = useState(0)
	const [guid, setGuid] = useState()
	const [progress, setProgress] = useState(progressInit)
	const [pctDone, setPctDone] = useState(0)
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const { video, analysisType, title='No title', brand, subbrand, project, comments, version, targetPlatform, industrySubCode, ownanalysis } = props.data
	const { todo, done } = progress
	const canSubmit = JSON.stringify(todo) === JSON.stringify(done)

	// listen for toast error events
	useEffect(() => {
		context.io.socket.on('toast', handleToastError)
		return () => context.io.socket.off('toast', handleToastError) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[context.io.socket])

	// handle toast errors
	function handleToastError(msg) {
		if (msg.severity === 'error') {
			alertObjectRef.current = { title:JSON.parse(msg.detail).reason }
			setAlertTask(()=>(action)=>{
				setAlertTask()
				handleNewClick() // go back to upload form
			}) // close alert on response
		}
	}

	// upload video and listen for analysis progress
	useEffect(() => {
		if (video) {

			// upload video file
			const data = new FormData()
			data.append('videofile', video, video.name)

			axios.post(process.env.REACT_APP_SAILS_URL + '/api/v1/video/upload', data, {
				withCredentials: true,
				onUploadProgress: ProgressEvent => {
					const pct = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
					setUploaded(pct)
					pct === 100 && setTimeout(() => {
						setUploading(false)
					}, 1500) // delay so user can see finished progress
				}
			})

			// upload done
			.then(res => {
				if (res.status === 200) { // file upload ok

					setGuid(res.data.guid)

					// send analysis data - delayed so animation can finish
					setTimeout(() => {
						context.io.socket.post('/api/v1/video', {
							guid: res.data.guid,
							analysisType: analysisType,
							title: title,
							brand: {name:brand},
							subbrand: subbrand,
							project: {name:project},
							comments: comments,
							version: version,
							targetPlatform: targetPlatform,
							industrySubCode: industrySubCode,
							ownanalysis: ownanalysis,
							filename: video.name }, (data, res) => {
							if (res.statusCode !== 200) {
								// TODO: handle error
							}
						})
					},1500)

					// listen for succesful verification event
					context.io.socket.on('video-verified', msg => { // TODO: use 'once' instead of 'on' here?

						// subscribe to progress events for this analysis
						context.io.socket.post('/api/v1/user/subscribe', { roomName: 'video-' + res.data.guid }, (data, res) => {
							if (res.statusCode !== 200) {
								// TODO: handle error
							}
						})

						// listen for analysis progress
						context.io.socket.on('video-progress-' + res.data.guid, msg => {
							// calculate progress percent
							const pct = (
								msg.done.transcode/msg.todo.transcode + 
								msg.done.preProc/msg.todo.preProc + 
								msg.done.objectDetection/msg.todo.objectDetection + 
								msg.done.objectDetectionPostProc/msg.todo.objectDetectionPostProc + 
								msg.done.mapImage/msg.todo.mapImage + 
								msg.done.flowsam/msg.todo.flowsam + 
								msg.done.mapImageBlend/msg.todo.mapImageBlend + 
								msg.done.attentionCalc/msg.todo.attentionCalc + 
								msg.done.statisticsCalc/msg.todo.statisticsCalc 
							) / 9 * 100
							pct < 100 ?
								window.localStorage.setItem('analysisProgress-' + res.data.guid, JSON.stringify(msg)) : // we can’t use useLocalStorage because we don’t have guid from the beginning so we do it "manually" here
								window.localStorage.removeItem('analysisProgress-' + res.data.guid) // remove localStorage item when 100% done
							setPctDone(pct)
							setProgress(msg)
						})

						// cache chunks as soon as they are ready for download
						context.io.socket.on('cache-img-' + res.data.guid, msg => {
							const tmpImg = new Image()
							switch(msg.type) {
								case 'maps-frames':
									tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + res.data.guid + '/maps-frames/' + res.data.guid + '-chunk' + ('0000000' + msg.chunk).slice(-7) + '.jpg'
									break
								case 'maps-frames-blend':
									tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + res.data.guid + '/maps-frames-blend/' + res.data.guid + '-chunk' + ('0000000' + msg.chunk).slice(-7) + '.jpg'
									break
								default:
									break
							}
						})
					})

				} else { // file upload failed
					// TODO: handle error - unless this is handled by a toast error
				}
			})
		}
		// cleanup on unmount
		return () => {
			context.io.socket.removeAllListeners('video-verified')
			guid && context.io.socket.removeAllListeners('video-progress-' + guid)
			guid && context.io.socket.removeAllListeners('cache-img-' + guid)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [analysisType, title, brand, subbrand, project, comments, version, targetPlatform, industrySubCode, ownanalysis, video, context.io.socket])

	// button click handler
	function handleClick() {
		props.action(guid)
	}

	// new analysis button enter handler
	function onEnter(e) {
		e.currentTarget.style.opacity = '1'
	}

	// new analysis button leave handler
	function onLeave(e) {	
		e.currentTarget.style.opacity = '.5'
	}

	// new analysis button click handler
	function handleNewClick() {
		props.new()
	}
	
	// find media type icon by targetPlatform id
	const mediaType = getMediaTypeById(targetPlatform)
	const mediaTypeIcon = mediaType !== undefined ? require('../../assets/images/media-type-icons/' + mediaType.icon) : iconAnalysis

	// new analysis button - show after upload when received guid
	const newButton = guid && <div className={styles.newButton} onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={handleNewClick}>&lt;&nbsp;&nbsp;New analysis&nbsp;&nbsp;</div>

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />
	
	const progressLabel = (
		uploading ? "Uploading..." :
		pctDone < 100 ? 
			done.transcode < todo.transcode ? "Transcoding..." :
			done.preProc < todo.preProc ? "Optical flow & frame extraction..." :
			done.objectDetection < todo.objectDetection ? "Object detection..." :
			done.objectDetectionPostProc < todo.objectDetectionPostProc ? "Object detection post processing..." : 
			done.mapImage < todo.mapImage ? "Frame sequence generation..." : 
			done.flowsam < todo.flowsam ? "Attention prediction..." :
			done.mapImageBlend < todo.mapImageBlend ? "Attention heat map generation..." :
			done.attentionCalc < todo.attentionCalc ? "Attention metrics calculation..." :
			done.statisticsCalc < todo.statisticsCalc ? "Crunching numbers..." : "Analyzing..." :
		"Analysis complete"
	)

	const bar = uploading ? <AnalysisProgressBar label={progressLabel} pct={uploaded} id='upload-progress' /> : <AnalysisProgressBar label={progressLabel} pct={pctDone} id='analysis-progress' />

	return (
		<div className={styles.container}>
			<h2 className={styles.heading}><img className={styles.icon} style={{transform: 'scale(1.3) translateY(' + (mediaType?.vpos * 1.4 - 3) + 'px)'}} src={mediaTypeIcon} alt="Analysis icon" />{title}</h2>
			<div className={styles.summary}>
				<div className={styles.summaryElement}>{brand || 'No brand'}</div>
				<div className={styles.summaryElement}>{project || 'No project'}</div>
				<div className={styles.summaryElement}>{mediaType?.label || 'No media type'}</div>
				<div className={styles.summaryElement}>{getIndustryBySubIndustryId(industrySubCode)?.label || 'No industry'}</div>
			</div>
			{bar}
			<button className={styles.button} tabIndex="-1" disabled={!canSubmit} onClick={handleClick}>TAG BRAND ASSETS</button>
			{newButton}
			{alert}
		</div>
	)
}
