import React, { useState, useRef } from 'react'
import AnimateHeight from 'react-animate-height'
import { industries, getIndustryBySubIndustryId } from '../../../utils/industries'
import InfoBubble from '../../common/InfoBubble'
import styles from './styles.module.css'

// component function
export default function Industry(props) {

	const { mediaTypeId, setSubIndustryId } = props
	const [selectedId, setSelectedId] = useState()
	const [height, setHeight] = useState(0)
	const [subHeights, setSubHeights] = useState([...new Array(industries.length)].map(() => 0))
	const activeMainIndustryIdRef = useRef()

	mediaTypeId && height === 0 && setHeight('auto') // reveal content if we have mediaTypeId

	// show/hide subindustry
	function toggleIndustry(e) {
		if (parseInt(e.currentTarget.dataset.industryid) === activeMainIndustryIdRef.current) return // don’t allow closing section if it contains a selected subindustry
		const id = parseInt(e.currentTarget.id.split('-')[1])
		const arr = [...subHeights]
		arr[id] = arr[id] === 0 ? 'auto' : 0
		setSubHeights(arr)
	}

	// industry selected
	function selectSubIndustry(e) {
		const id = parseInt(e.currentTarget.id.split('-')[1])
		activeMainIndustryIdRef.current = getIndustryBySubIndustryId(id)?.id
		setSelectedId(id)
		setSubIndustryId(id) // inform parent
	}

	const infoWidth = 160 - (window.innerWidth > 767 ? Math.max((830 - (window.innerWidth-10))/2, 0) : 0)
	const infoPos   = window.innerWidth > 767 ? 'right' : 'left'
	const infoMove  = window.innerWidth > 767 ? -8 : 2
	const infoDist  = window.innerWidth > 767 ? 13 : 9

	// render industry/subindustry list
	const industryList = industries.map((industry, i) => {
		return (
			<div key={'ind-'+i} className={styles.industry + (subHeights[i] !== 0 ? ' ' + styles.nohover : '')}>
				<div id={'ind-'+i} data-industryid={industry.id} className={styles.industryLabel + (activeMainIndustryIdRef.current === industry.id ? ' ' + styles.nopointer : '')} onClick={toggleIndustry}>{industry.label}</div>
				<AnimateHeight duration={500} height={subHeights[i]}>
					{industry.subindustries.map((sub) => {
						const selected = selectedId === sub.id
						const iconStyle = {
							backgroundImage: 'url(' + require('../../../assets/images/industry-icons/' + (selected ? sub.iconHighlight : sub.icon)) + ')',
							backgroundPosition: '23px 50%'
						}
						return (
							<div key={'sub-'+sub.id} id={'sub-'+sub.id} className={styles.subIndustry + (selected ? ' ' + styles.subIndustryInverse : '')} style={iconStyle} onClick={selectSubIndustry}>
								<div className={styles.subIndustryLabel + (selected ? ' ' + styles.subIndustryLabelInverse : '')}>{sub.label}</div>
								<InfoBubble info={sub.info} pos={infoPos} size={infoWidth} dist={infoDist} move={infoMove} inverse={selected} />
							</div>
						)
					})}
				</AnimateHeight>
			</div>
		)
	})

	return (
		<>
			<div className={styles.sectionHeading}>3. Industry
				<span className={styles.infoBubble + (mediaTypeId !== undefined ? ' ' + styles.show : '')}>
					<InfoBubble pos='bottom' size={258} dist={9} move={-190} info='Pick a category that best matches the product area being advertised. If the ad is designed to promote a brand operating across multiple categories, pick the category the brand is best known for.' />
				</span>
			</div>
			<AnimateHeight duration={1000} height={height}>
				<div className={styles.container}>
					{industryList}
				</div>
			</AnimateHeight>
		</>
	)
}
