import React, { useState, useEffect, useContext } from 'react'
import { APIContext } from '../../../utils/api'
import Switch from 'react-switch'
import AnimateHeight from 'react-animate-height'
import DropDown from '../../common/DropDown'
import { mediaTypes } from '../../../utils/mediaTypes'
import { industries } from '../../../utils/industries'
import { colors } from '../../../themes/colors'
import styles from './styles.module.css'

// component function
export default function AnalysisListItemDetails(props) {

	const { item, closeDetails, projects, brands, updateProjects, updateBrands, setAlertTask, alertObjectRef } = props
	item.brand = item.brand ? item.brand : { id:null, name:null }

	const context = useContext(APIContext)
	const [height, setHeight] = useState(0)
	const [projectOptions, setProjectOptions] = useState(projects)
	const [brandOptions, setBrandOptions] = useState(brands)
	const [title, setTitle] = useState(item.title)
	const [brand, setBrand] = useState({ value:null, label:item.brand.name })
	const [subBrand, setSubBrand] = useState(item.subbrand !== 'NULL' && item.subbrand !== null ? item.subbrand : '')
	const [project, setProject] = useState({ value:null, label:item.project.name })
	const [comments, setComments] = useState(item.comments !== 'NULL' && item.comments !== null ? item.comments : '')
	const [version, setVersion] = useState(item.version !== 'NULL' && item.version !== null ? item.version : '')
	const [mediaTypeId, setMediaTypeId] = useState(item.targetPlatform === null ? 0 : item.targetPlatform)
	const [subIndustryId, setSubIndustryId] = useState(item.industrySubCode === null ? 0 : item.industrySubCode)
	const [ownAnalysis, setOwnAnalysis] = useState(item.ownanalysis !== 0 ? true : false)
	const [submitting, setSubmitting] = useState(false)

	// en-/disable submit button
	const canSubmit = (
		(title !== item.title) ||
		(item.brand && brand.label !== item.brand.name) ||
		((item.subbrand === 'NULL' || item.subbrand === null) && subBrand !== '') || (item.subbrand !== 'NULL' && item.subbrand !== null && subBrand !== item.subbrand) ||
		(project.label !== item.project.name) ||
		((item.comments === 'NULL' || item.comments === null) && comments !== '') || (item.comments !== 'NULL' && item.comments !== null && comments !== item.comments) ||
		((item.version === 'NULL' || item.version === null) && version !== '') || (item.version !== 'NULL' && item.version !== null && version !== item.version) ||
		(item.targetPlatform === null && parseInt(mediaTypeId) !== 0) || (item.targetPlatform !== null && parseInt(mediaTypeId) !== parseInt(item.targetPlatform)) ||
		(item.industrySubCode === null && parseInt(subIndustryId) !== 0) || (item.industrySubCode !== null && parseInt(subIndustryId) !== parseInt(item.industrySubCode)) ||
		(item.ownanalysis === null && ownAnalysis === false) ||
		(item.ownanalysis !== null && ((ownAnalysis === false && parseInt(item.ownanalysis) === 1) || (ownAnalysis === true && parseInt(item.ownanalysis) === 0)))
	)

	// update projects and brands on change
	useEffect(() => {
		setProjectOptions(projects)
		setBrandOptions(brands)
	}, [projects, brands])

	// toggle analysis type handler
	function toggleAnalysisType() {
		setOwnAnalysis(!ownAnalysis)
	}

	// validate form data and submit if all ok
	function validateAndSubmit() {
		if (!title.trim()) {
			showAlert('Please enter a name for your analysis')
			closeDetails(true)
		} else if (!brand) {
			showAlert('Please select or create a brand for your analysis')
			closeDetails(true)
		} else if (!project) {
			showAlert('Please select or create a project for your analysis')
			closeDetails(true)
		} else if (!mediaTypeId) {
			showAlert('Please select a media type for your analysis')
			closeDetails(true)
		} else if (!subIndustryId) {
			showAlert('Please select an industry for your analysis')
			closeDetails(true)
		} else if (canSubmit) {
			submitDetails()
		}
	}

	// do we need to recalculate averages?
	function calculateNeeded() {
		return (item.targetPlatform === null && parseInt(mediaTypeId) !== 0) || (item.targetPlatform !== null && parseInt(mediaTypeId) !== parseInt(item.targetPlatform)) ||
		(item.industrySubCode === null && parseInt(subIndustryId) !== 0) || (item.industrySubCode !== null && parseInt(subIndustryId) !== parseInt(item.industrySubCode)) ||
		(item.ownanalysis === null && ownAnalysis === false) ||
		(item.ownanalysis !== null && ((ownAnalysis === false && parseInt(item.ownanalysis) === 1) || (ownAnalysis === true && parseInt(item.ownanalysis) === 0)))
	}

	// submit data
	function submitDetails() {
		setSubmitting(true)
		const videoUpdateRequest = {
			title: title,
			brand: { name: brand.label },
			subbrand: subBrand,
			project: { name: project.label },
			comments: comments,
			version: version,
			targetPlatform: mediaTypeId,
			industrySubCode: subIndustryId,
			ownanalysis: ownAnalysis,
			objectSegmentFilter: item.objectSegmentFilter
		}
		// send details data
		const calculate = calculateNeeded() ? "" : "?dont_calculate"
		context.io.socket.post('/api/v1/video/' + item.guid + calculate, videoUpdateRequest, (data, res) => {
			if (res.statusCode === 200) {
				// refresh parent project- and/or brand list if new project and/or brand was created
				brandOptions.find(br => br.label === brand.label) === undefined && updateBrands()
				projectOptions.find(proj => proj.label === project.label) === undefined && updateProjects()
				// update item details
				item.title = title
				item.project.name = project.label
				item.brand.name = brand.label
				item.subbrand = subBrand
				item.comments = comments
				item.version = version
				item.targetPlatform = mediaTypeId
				item.industrySubCode = subIndustryId
				item.ownanalysis = ownAnalysis === true ? 1 : 0			
				// close
				setSubmitting(false)
				setHeight(0)
				setTimeout(closeDetails,(height !== 0 ? 400 : 0))
			} else {
				setSubmitting(false)
				// TODO: error handling
			}
		})
	}

	// reveal/hide extra options
	function toggleHeight() {
		setHeight(height !== 0 ? 0 : 'auto')
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// close details overlay
	function closeDetailsOverlay() {
		// confirm close or save if changes
		if (canSubmit && !submitting) {
			alertObjectRef.current = { type:'confirm', title:'Save ' + (calculateNeeded() ? 'and calculate ' : '') + 'changes?', cancelLabel:'Don’t save', actionLabel:'Yes, save' }
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					validateAndSubmit()
				} else {
					// reset values
					setTitle(item.title)
					setProject({ value:null, label:item.project.name })
					setBrand({ value:null, label:item.brand.name })
					setSubBrand((item.subbrand !== 'NULL' && item.subbrand !== null) ? item.subbrand : '')
					setComments((item.comments !== 'NULL' && item.comments !== null) ? item.comments : '')
					setVersion((item.version !== 'NULL' && item.version !== null) ? item.version : '')
					setMediaTypeId(item.targetPlatform === null ? 0 : item.targetPlatform)
					setSubIndustryId(item.industrySubCode === null ? 0 : item.industrySubCode)
					setOwnAnalysis(item.ownanalysis !== 0 ? 1 : 0)
					setHeight(0)
					setTimeout(closeDetails,(height !== 0 ? 400 : 0))
				}
				setAlertTask() // remove alert
			})
		} else {
			setHeight(0)
			setTimeout(closeDetails,(height !== 0 ? 400 : 0))
		}
	}

	// update media type id
	function updateMediaTypeId(e) {
		setMediaTypeId(e.target.value)
	}

	// update sub industry id
	function updateSubIndustryId(e) {
		setSubIndustryId(e.target.value)
	}

	// analysis type toggle
	const analysisTypeToggle = (
		<div className={styles.switchContainer}>
			<div className={ownAnalysis ? styles.enabled : styles.disabled} onClick={toggleAnalysisType}>Own ad</div>
			<Switch
				onChange={toggleAnalysisType}
				checked={!ownAnalysis}
				offColor={colors.switchBackground1}
				offHandleColor={colors.switchKnobOn}
				onColor={colors.switchBackground1}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={16}
				width={30}
				handleDiameter={16}
				activeBoxShadow=''
				id={'analysis-type-switch-'+item.guid}
			/>
			<div className={ownAnalysis ? styles.disabled : styles.enabled} onClick={toggleAnalysisType}>Competitor ad</div>
		</div>
	)

	// media types filtered on image/video type and aspect ratio
	const mediaRatio = item.streamInfo?.displayAspectRatio || 2
	const mediaTypeOptions = mediaTypes.filter(item.analysisType === 3 ? t => t.mediaType === 'image' :  t => t.mediaType === 'video' && (t.minRatio === undefined  || mediaRatio > t.minRatio)).map(type => {
		return <option key={'mediatype-'+type.id+'-'+item.guid} value={type.id}>{type.label}</option>
	})

	// industries grouped for select options
	const subIndustryOptions = industries.map(industry => {
		return <optgroup key={'industry-'+industry.id+'-'+item.guid} label={industry.label}>
			{industry.subindustries.map(sub => {
				return <option key={'subindustry-'+sub.id+'-'+item.guid} value={sub.id}>{sub.label}</option>
			})}
		</optgroup>
	})

	return (
		<>
			<div className={styles.container}>
				<div className={styles.closeButton} onClick={closeDetailsOverlay}>&times;</div>
				<div>
					<div className={styles.inputLabel}>Analysis name</div>
					<input name="title" value={title} onChange={(e) => setTitle(e.target.value)} className={styles.inputText} />
				</div>
				<div className={styles.multiColumn}>
					<div className={styles.distribute}>
						<div className={styles.inputLabel}>Brand</div>
						<DropDown
							value={brand}
							options={brandOptions}
							onChange={(label)=>setBrand({ value:null, label:label })}
							searchable={true} small={true}
							placeholder="Select/create"
						/>
					</div>
					<div className={styles.distribute}>
						<div className={styles.inputLabel}>Sub brand</div>
						<input name="subbrand" value={subBrand} onChange={(e) => setSubBrand(e.target.value)} className={styles.inputText} />
					</div>
				</div>
				<div>
					<div className={styles.inputLabel}>Project</div>
					<DropDown
						value={project}
						options={projectOptions}
						onChange={(label)=>setProject({ value:null, label:label })}
						searchable={true} small={true}
						placeholder="Select/create"
					/>
				</div>
				<div>
					<div className={styles.inputLabel}>Comments</div>
					<input name="comments" value={comments} onChange={(e) => setComments(e.target.value)} className={styles.inputText} />
				</div>
				<div className={styles.multiColumn}>
					<div className={styles.distribute}>
						<div className={styles.inputLabel}>Version</div>
						<input name="version" value={version} onChange={(e) => setVersion(e.target.value)} className={styles.inputText} />
					</div>
					<div className={styles.button + ' ' + styles.more} onClick={toggleHeight}>{height !== 0 ? 'Less...' : 'More...'}</div>
					<div className={styles.button + ' ' + styles.submit + (!canSubmit ? ' ' + styles.disabled : '')} onClick={validateAndSubmit}>SAVE</div>
				</div>
				<AnimateHeight duration={400} height={height} disableDisplayNone={true}>
					<div className={styles.moreContainer}>
						<div className={styles.moreDescription}>Changes to the details below will require a new calculation of the analysis. This may take a while.</div>
						<div>
							<div className={styles.inputLabel}>Media type</div>
							<div className={styles.selectWrapper}>
								<select value={mediaTypeId} className={styles.select} onChange={updateMediaTypeId}>
									{mediaTypeId === 0 && <option value={0}>Unknown</option>}
									{mediaTypeOptions}
								</select>
							</div>
						</div>
						<div>
							<div className={styles.inputLabel}>Industry</div>
							<div className={styles.selectWrapper}>
								<select value={subIndustryId} className={styles.select} onChange={updateSubIndustryId}>
									{subIndustryId === 0 && <option value={0}>Unknown</option>}
									{subIndustryOptions}
								</select>
							</div>
						</div>
					</div>
					{analysisTypeToggle}
				</AnimateHeight>
			</div>
		</>
	)
}
